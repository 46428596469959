@font-face {
  font-family: "MainFont";
  src: url("./fonts/MainFont.woff2") format("woff2"), url("./fonts/MainFont.woff") format("woff"),
    url("./fonts/MainFont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MainFont";
  src: url("./fonts/MainFont-Bold.woff2") format("woff2"),
    url("./fonts/MainFont-Bold.woff") format("woff"),
    url("./fonts/MainFont-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "MainFont";
  src: url("./fonts/MainFont-Italic.woff2") format("woff2"),
    url("./fonts/MainFont-Italic.woff") format("woff"),
    url("./fonts/MainFont-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "MainFont";
  src: url("./fonts/MainFont-BoldItalic.woff2") format("woff2"),
    url("./fonts/MainFont-BoldItalic.woff") format("woff"),
    url("./fonts/MainFont-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "StyledFont";
  src: url("./fonts/StyledFont.woff2") format("woff2"),
    url("./fonts/StyledFont.woff") format("woff"),
    url("./fonts/StyledFont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "StyledFont";
  src: url("./fonts/StyledFont-Bold.woff2") format("woff2"),
    url("./fonts/StyledFont-Bold.woff") format("woff"),
    url("./fonts/StyledFont-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "StyledFont";
  src: url("./fonts/StyledFont-Italic.woff2") format("woff2"),
    url("./fonts/StyledFont-Italic.woff") format("woff"),
    url("./fonts/StyledFont-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "StyledFont";
  src: url("./fonts/StyledFont-BoldItalic.woff2") format("woff2"),
    url("./fonts/StyledFont-BoldItalic.woff") format("woff"),
    url("./fonts/StyledFont-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

body {
  margin: 0;
  font-family: "MainFont", "Roboto";
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.submenu-boxlist-container .box-item-title{
  font-size: 1.5em!important;
}

@media (max-height: 700px){
  .submenu-boxlist-container .box-item-title{
    font-size: 1em!important;
  }
  .submenu-boxlist-container .box-item{
    padding: 0.5em!important;
  }
  .box-item-title{
    font-size: 1.5em!important;
  }

}


